import React, { Component } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import Header from '../common/Header'
import Footer from '../common/Footer'
import Nav from '../common/Nav'

export default class Transactions extends Component {
    render() {
        return (
            <>
                <Header />
                <div id="appCapsule">
                    <div className="listview-title mt-1">Service (Transactions)</div>
                    <ul className="listview image-listview inset mb-2">
                        <li>
                            <Link to="/aeps-transactions" className="item">
                                <div className="icon-box bg-primary">
                                    <ion-icon name="cube-outline"></ion-icon>
                                </div>
                                <div className="in">
                                    AEPS
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/dmt-transactions" className="item">
                                <div className="icon-box bg-primary">
                                    <ion-icon name="cube-outline"></ion-icon>
                                </div>
                                <div className="in">
                                    DMT
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/imps-transactions" className="item">
                                <div className="icon-box bg-primary">
                                    <ion-icon name="cube-outline"></ion-icon>
                                </div>
                                <div className="in">
                                    IMPS Transfer
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/recharge-transactions" className="item">
                                <div className="icon-box bg-primary">
                                    <ion-icon name="cube-outline"></ion-icon>
                                </div>
                                <div className="in">
                                    Recharge
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/bbps-transactions" className="item">
                                <div className="icon-box bg-primary">
                                    <ion-icon name="cube-outline"></ion-icon>
                                </div>
                                <div className="in">
                                    BBPS
                                </div>
                            </Link>
                        </li>
                        
                    </ul>
                    <div className="listview-title">Wallet Reports</div>
                    <ul className="listview image-listview inset mb-2">
                        <li>
                            <Link to="/retailer-wallet-report" className="item">
                                <div className="icon-box bg-info">
                                    <ion-icon name="cube-outline"></ion-icon>
                                </div>
                                <div className="in">
                                    Retailer Wallet
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/distributor-wallet-report" className="item">
                                <div className="icon-box bg-info">
                                    <ion-icon name="cube-outline"></ion-icon>
                                </div>
                                <div className="in">
                                    Distributor Wallet
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/master-wallet-report" className="item">
                                <div className="icon-box bg-info">
                                    <ion-icon name="cube-outline"></ion-icon>
                                </div>
                                <div className="in">
                                    Master Wallet
                                </div>
                            </Link>
                        </li>
                    </ul>                    
                </div>

                <Footer />
                <Nav />
            </>
        )
    }
}
