import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class AuthGuard extends Component {
  componentDidMount() {
    const token = localStorage.getItem('token');
    if (!token) {
      // Redirect to login if token doesn't exist
      this.props.history.push('/login');
    } else {
        this.props.history.push('/');
    }
  }

  render() {
    // This component doesn't render anything, so return null
    return null;
  }
}

export default withRouter(AuthGuard);