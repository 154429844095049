import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export default class Footer extends Component {
    
  render() {
    return (
        <>
       
            <div className="appBottomMenu">
                <Link to="/" className="item">
                    <div className="col">
                        <ion-icon name="pie-chart-outline"></ion-icon>
                        <strong>Dashboard</strong>
                    </div>
                </Link>
                <Link to="/transactions" className="item">
                    <div className="col">
                        <ion-icon name="document-text-outline"></ion-icon>
                        <strong>Transaction</strong>
                    </div>
                </Link>
                <Link to="/transactions" className="item">
                    <div className="col">
                        <ion-icon name="apps-outline"></ion-icon>
                        <strong>Components</strong>
                    </div>
                </Link>
                <Link to="/user-list" className="item">
                    <div className="col">
                        <ion-icon name="people-outline"></ion-icon>
                        <strong>Users</strong>
                    </div>
                </Link>
                <Link to="/app-settings" className="item">
                    <div className="col">
                        <ion-icon name="settings-outline"></ion-icon>
                        <strong>Settings</strong>
                    </div>
                </Link>
            </div>
        
        </>
    )
  }
}
