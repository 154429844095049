import React, { Component } from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer'
import Nav from '../common/Nav'

export default class Home extends Component {
    render() {
        return (
            <>
                <Header />
                <div id="appCapsule">
                    <div className="section wallet-card-section pt-1">
                        <div className="wallet-card">
                            <div className="balance">
                                <div className="left">
                                    <span className="title">Total Balance</span>
                                    <h1 className="total">$ 2,562.50</h1>
                                </div>
                                <div className="right">
                                    <a href="#" className="button" data-bs-toggle="modal" data-bs-target="#depositActionSheet">
                                        <ion-icon name="add-outline"></ion-icon>
                                    </a>
                                </div>
                            </div>
                            <div className="wallet-footer">
                                <div className="item">
                                    <a href="#" data-bs-toggle="modal" data-bs-target="#withdrawActionSheet">
                                        <div className="icon-wrapper bg-danger">
                                            <ion-icon name="arrow-down-outline"></ion-icon>
                                        </div>
                                        <strong>Withdraw</strong>
                                    </a>
                                </div>
                                <div className="item">
                                    <a href="#" data-bs-toggle="modal" data-bs-target="#sendActionSheet">
                                        <div className="icon-wrapper">
                                            <ion-icon name="arrow-forward-outline"></ion-icon>
                                        </div>
                                        <strong>Send</strong>
                                    </a>
                                </div>
                                <div className="item">
                                    <a href="app-cards.html">
                                        <div className="icon-wrapper bg-success">
                                            <ion-icon name="card-outline"></ion-icon>
                                        </div>
                                        <strong>Cards</strong>
                                    </a>
                                </div>
                                <div className="item">
                                    <a href="#" data-bs-toggle="modal" data-bs-target="#exchangeActionSheet">
                                        <div className="icon-wrapper bg-warning">
                                            <ion-icon name="swap-vertical"></ion-icon>
                                        </div>
                                        <strong>Exchange</strong>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade action-sheet" id="depositActionSheet" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Add Balance</h5>
                                </div>
                                <div className="modal-body">
                                    <div className="action-sheet-content">
                                        <form>
                                            <div className="form-group basic">
                                                <div className="input-wrapper">
                                                    <label className="label" >From</label>
                                                    <select className="form-control custom-select" id="account1">
                                                        <option value="0">Savings (*** 5019)</option>
                                                        <option value="1">Investment (*** 6212)</option>
                                                        <option value="2">Mortgage (*** 5021)</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group basic">
                                                <label className="label">Enter Amount</label>
                                                <div className="input-group mb-2">
                                                    <span className="input-group-text" id="basic-addona1">$</span>
                                                    <input type="text" className="form-control" placeholder="Enter an amount" defaultValue="100" />
                                                </div>
                                            </div>
                                            <div className="form-group basic">
                                                <button type="button" className="btn btn-primary btn-block btn-lg" data-bs-dismiss="modal">Deposit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade action-sheet" id="withdrawActionSheet" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Withdraw Money</h5>
                                </div>
                                <div className="modal-body">
                                    <div className="action-sheet-content">
                                        <form>
                                            <div className="form-group basic">
                                                <div className="input-wrapper">
                                                    <label className="label" >From</label>
                                                    <select className="form-control custom-select" id="account2d">
                                                        <option value="0">Savings (*** 5019)</option>
                                                        <option value="1">Investment (*** 6212)</option>
                                                        <option value="2">Mortgage (*** 5021)</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group basic">
                                                <div className="input-wrapper">
                                                    <label className="label" >To</label>
                                                    <input type="email" className="form-control" id="text11d" placeholder="Enter IBAN" />
                                                    <i className="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                            <div className="form-group basic">
                                                <label className="label">Enter Amount</label>
                                                <div className="input-group mb-2">
                                                    <span className="input-group-text" id="basic-addonb1">$</span>
                                                    <input type="text" className="form-control" placeholder="Enter an amount" defaultValue="100" />
                                                </div>
                                            </div>
                                            <div className="form-group basic">
                                                <button type="button" className="btn btn-primary btn-block btn-lg" data-bs-dismiss="modal">Withdraw</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade action-sheet" id="sendActionSheet" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Send Money</h5>
                                </div>
                                <div className="modal-body">
                                    <div className="action-sheet-content">
                                        <form>
                                            <div className="form-group basic">
                                                <div className="input-wrapper">
                                                    <label className="label" >From</label>
                                                    <select className="form-control custom-select" id="account2">
                                                        <option value="0">Savings (*** 5019)</option>
                                                        <option value="1">Investment (*** 6212)</option>
                                                        <option value="2">Mortgage (*** 5021)</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group basic">
                                                <div className="input-wrapper">
                                                    <label className="label" >To</label>
                                                    <input type="email" className="form-control" id="text11" placeholder="Enter bank ID" />
                                                    <i className="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                </div>
                                            </div>
                                            <div className="form-group basic">
                                                <label className="label">Enter Amount</label>
                                                <div className="input-group mb-2">
                                                    <span className="input-group-text" id="basic-addon1">$</span>
                                                    <input type="text" className="form-control" placeholder="Enter an amount" defaultValue="100" />
                                                </div>
                                            </div>
                                            <div className="form-group basic">
                                                <button type="button" className="btn btn-primary btn-block btn-lg" data-bs-dismiss="modal">Send</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade action-sheet" id="exchangeActionSheet" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Exchange Money</h5>
                                </div>
                                <div className="modal-body">
                                    <div className="action-sheet-content">
                                        <form>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group basic">
                                                        <div className="input-wrapper">
                                                            <label className="label" >From</label>
                                                            <select className="form-control custom-select" id="currency1">
                                                                <option value="1">EUR</option>
                                                                <option value="2">USD</option>
                                                                <option value="3">AUD</option>
                                                                <option value="4">CAD</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group basic">
                                                        <div className="input-wrapper">
                                                            <label className="label" >To</label>
                                                            <select className="form-control custom-select" id="currency2">
                                                                <option value="1">USD</option>
                                                                <option value="1">EUR</option>
                                                                <option value="2">AUD</option>
                                                                <option value="3">CAD</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group basic">
                                                <label className="label">Enter Amount</label>
                                                <div className="input-group mb-2">
                                                    <span className="input-group-text" id="basic-addon2">$</span>
                                                    <input type="text" className="form-control" placeholder="Enter an amount" defaultValue="100" />
                                                </div>
                                            </div>
                                            <div className="form-group basic">
                                                <button type="button" className="btn btn-primary btn-block btn-lg" data-bs-dismiss="modal">Exchange</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="row mt-2">
                            <div className="col-6">
                                <div className="stat-box">
                                    <div className="title">Income</div>
                                    <div className="value text-success">$ 552.95</div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="stat-box">
                                    <div className="title">Expenses</div>
                                    <div className="value text-danger">$ 86.45</div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2 mb-5">
                            <div className="col-6">
                                <div className="stat-box">
                                    <div className="title">Total Bills</div>
                                    <div className="value">$ 53.25</div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="stat-box">
                                    <div className="title">Savings</div>
                                    <div className="value">$ 120.99</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="appFooter">
                        <div className="footer-title">
                            Copyright © Diosm Fintect Pvt. Ltd. 2024. All Rights Reserved.
                        </div>
                    </div>

                </div>

                <Footer />
                <Nav />
            </>
        )
    }
}
