import React, { Component } from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Home from './components/pages/Home'
import Transactions from './components/pages/Transactions'
import UserList from './components/pages/UserList';
import Login from './components/pages/Login';
import Logout from './components/pages/Logout';
import AuthGuard from './components/common/AuthGaurd';
import { BrowserRouter as Router, Route } from 'react-router-dom'

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scriptsLoaded: false,
      pageLoaded: true
    };
  }

  componentDidMount() {
    // Load scripts only if they haven't been loaded yet
    //console.log('componentDidMount');
    if (!this.state.scriptsLoaded) {
      this.loadScripts();
    }
    setTimeout(() => {
      this.setState({ pageLoaded: false });
    }, 1000); // 1000 milliseconds = 1 second (adjust as needed)
  }

  loadScripts() {
    // Load Ionicons JS
    const ioniconsScript = document.createElement('script');
    ioniconsScript.type = 'module';
    ioniconsScript.src = 'https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.esm.js';
    document.body.appendChild(ioniconsScript);

    const ioniconsNomoduleScript = document.createElement('script');
    ioniconsNomoduleScript.setAttribute('noModule', ''); // Fix here
    ioniconsNomoduleScript.src = 'https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.js';
    document.body.appendChild(ioniconsNomoduleScript);

    this.setState({ scriptsLoaded: true });

    // Store the scripts in the component state to clean them up later
    this.scripts = [
      ioniconsScript,
      ioniconsNomoduleScript
    ];
  }

  componentWillUnmount() {
     //Clean up scripts when the component unmounts
    this.scripts.forEach(script => {
      document.body.removeChild(script);
    });
  }
  render() {
    const { pageLoaded } = this.state;
    //console.log('render');
  return (
    <>
    { pageLoaded ? (
      <div id="loader">
          <img src="assets/img/loading-icon.png" alt="icon" className="loading-icon" />
      </div>
      ) : null }
      <Router>
        <Route exact path="/login" component={Login} />
        <AuthGuard />
        <Route exact path="/" component={Home} />
        <Route exact path="/transactions" component={Transactions} />
        <Route exact path="/user-list" component={UserList} />
        <Route path="/logout" component={Logout} />
      </Router>
      
    </>
  )
}
}

