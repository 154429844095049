import React, { Component } from 'react'

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: '',
            password: '',
            pin: '',
            toastShow: false,
            toastClass: '',
            toastMsg: '',
            loginVerify: false
        };
    }

    userLoginVerify = async (submitType) => {
        console.log(submitType);
        const { userId, password, pin } = this.state;
        if(submitType == 'pinVerify'){
            var jsonBody = {
                ltln: '28.66977890,+77.34391700',
                userid: userId,
                password: password,
                pin: pin
            };
            var endUrl = 'https://api.diosm.co.in/api/users/verifyPin';
        } else {
            var jsonBody = {
                ltln: '28.66977890,+77.34391700',
                userid: userId,
                password: password,
                login: 'web'
            };
            var endUrl = 'https://api.diosm.co.in/api/users/login';
        }
        
        try {
            const response = await fetch(endUrl, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jsonBody)
            });

            if (!response.ok) {
                throw new Error('Failed to login verification');
            }

            const res = await response.json();
            if (res.ERROR_CODE == '00') {                
                if(submitType == 'pinVerify'){
                    this.setState({ pin: '' });
                    localStorage.setItem('token', res.TOKEN);
                    localStorage.setItem('userType', res.USER_TYPE);
                    localStorage.setItem('displayName', res.PROFILE_NAME);
                    localStorage.setItem('userId', res.LOGIN_ID);
                    this.props.history.push('/');
                } else {
                    this.setState({ loginVerify: true });
                }
                this.setState({ toastMsg: res.MESSAGE, toastShow: true, toastClass: 'bg-success' });
                setTimeout(() => {
                    this.setState({ toastShow: false });
                }, 3000);
            } else {
                if(submitType == 'pinVerify'){
                    this.setState({ pin: '' });
                } else {
                    this.setState({ loginVerify: false });
                }
                this.setState({ toastMsg: res.MESSAGE, toastShow: true, toastClass: 'bg-danger' });
                setTimeout(() => {
                    this.setState({ toastShow: false });
                }, 3000);
                return
            }

        } catch (error) {
            console.error('Error login :', error);
            // Handle error, show error message, etc.
            this.setState({ loading: false });
        }
    };

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        //console.log(name);
      };
    handleSubmit = (e) => {
        e.preventDefault();
        const { userId, password, loginVerify } = this.state;
        if (!userId.trim()) {
            this.setState({ toastMsg: 'Please enter your userid', toastShow: true, toastClass: 'bg-danger' });
            setTimeout(() => {
                this.setState({ toastShow: false });
            }, 3000);
            return
        }
        if (!password.trim()) {
            this.setState({ toastMsg: 'Please enter your password', toastShow: true, toastClass: 'bg-danger' });
            setTimeout(() => {
                this.setState({ toastShow: false });
            }, 3000);
            return
        }
        if(loginVerify){
            this.userLoginVerify('pinVerify');
        } else {
            this.userLoginVerify('loginVerify');
        }
       
        
        //console.log(this.state);
    }
    handleCloseToast = () => {
        this.setState({ toastShow: false });
    };
    render() {
        const { toastShow,toastMsg,toastClass,loginVerify } = this.state;
        return (
            <>
                {loginVerify ?
                    (<div id="appCapsule">

                        <div className="section mt-2 text-center">
                            <h1>Enter Login Pin</h1>
                            <h4>Enter 4 digit login pin</h4>
                        </div>
                        <div className="section mb-5 p-2">
                            <form >
                                <div className="form-group basic">
                                    <input type="password" className="form-control verification-input" id="smscode" placeholder="••••" maxLength="4" onChange={this.handleInputChange} name="pin" value={this.state.pin} />
                                </div>

                                <div className="form-button-group transparent">
                                    <button type="button" onClick={this.handleSubmit}  className="btn btn-primary btn-block btn-lg">Verify</button>
                                </div>

                            </form>
                        </div>

                        <div id="toast-16" className={`toast-box toast-top ${toastShow ? 'show ' + toastClass : ''}`} style={{ 'top': toastShow ? '0px' : undefined }}>
                            <div className="in">
                                <div className="text">
                                    {toastMsg}
                                </div>
                            </div>
                            <button type="button" onClick={this.handleCloseToast} className="btn btn-sm btn-text-light close-button">OK</button>
                        </div>

                    </div>)
                    :

                    (<div id="appCapsule">

                        <div className="section mt-2 text-center">
                            <h1>Log in</h1>
                            <h4>Fill the form to log in</h4>
                        </div>
                        <div className="section mb-5 p-2">

                            <form >
                                <div className="card">
                                    <div className="card-body pb-1">
                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="label" >User Id</label>
                                                <input type="email" className="form-control" onChange={this.handleInputChange} name="userId" value={this.state.userId} placeholder="Your UserId" />
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>

                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="label" >Password</label>
                                                <input type="password" className="form-control" onChange={this.handleInputChange} name="password" value={this.state.password} placeholder="Your password" />
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="form-links mt-2">
                                    <div></div>
                                    <div><a href="#" className="text-muted">Forgot Password?</a></div>
                                </div>

                                <div className="form-button-group  transparent">
                                    <button type="button" onClick={this.handleSubmit} className="btn btn-primary btn-block btn-lg">Log in</button>
                                </div>

                            </form>
                        </div>

                        <div id="toast-16" className={`toast-box toast-top ${toastShow ? 'show ' + toastClass : ''}`} style={{ 'top': toastShow ? '0px' : undefined }}>
                            <div className="in">
                                <div className="text">
                                    {toastMsg}
                                </div>
                            </div>
                            <button type="button" onClick={this.handleCloseToast} className="btn btn-sm btn-text-light close-button">OK</button>
                        </div>
                    </div>)
                }
            </>
        )
    }
}
