import React, { Component } from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer'
import Nav from '../common/Nav'

export default class UserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userList: [],
            loading: true,
            totalResult: 0,
            resultShowing: '0 to 0',
            offsetStart : 0,
            remainingData: 0,
            userType: '',
            searchKey: '',
            userDetails: []
        };
    }

    componentDidMount() {
        const token = localStorage.getItem('token');
        if(!token){
            this.props.history.push('/login');
        } else {
            this.fetchUserList();
        }
        
    }

    fetchUserList = async () => {
		const token = localStorage.getItem('token');
        const { offsetStart, userList,userType,searchKey  } = this.state;
        const ut = (userType != '0') ? userType : '';
        try {
            const response = await fetch('https://api.diosm.co.in/api/users/list', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
					'token': token
                },
                body: JSON.stringify({
                    length: 50, // Adjust as needed
                    searchKey: searchKey,
                    userType: ut,
                    start : offsetStart
                })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch user list');
            }

            const res = await response.json();
            const resultShowingText = (res.to && res.to > 0) ? '1 to ' + res.to : '0 to 0';
            
            this.setState({ 
                userList: [...userList, ...res.data],
                loading: false,
                totalResult: res.total,
                resultShowing : resultShowingText,
                offsetStart : res.to,
                remainingData : (res.total - res.to)
            });
        } catch (error) {
            console.error('Error fetching user list:', error);
            // Handle error, show error message, etc.
            this.setState({ loading: false });
        }
    };

    loadMore = () => {
        this.fetchUserList();
    }

    goBack = () => {
        this.props.history.goBack(); // Go back to the previous page
    }

    viewUserDetail = (e) => {
        const userInfo = e.currentTarget.dataset.userinfo;
        this.setState({ userDetails : JSON.parse(userInfo) });
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        //console.log(name);
      };
    handleSubmit = (e) => {
        //e.preventDefault();
        this.setState({ 
            userList: [],
            loading: true,
            totalResult: 0,
            resultShowing: '0 to 0',
            offsetStart : 0,
            remainingData: 0
        }, () => {
            // Callback function called after userList is updated
            this.fetchUserList(); 
        });
        //console.log(this.state);
    };
    render() {
        const { userList, loading, totalResult, resultShowing, remainingData, userDetails } = this.state;
        
        //console.log(userDetails);
        return (
            <>
                <div className="appHeader">
                    <div className="left">
                        <button onClick={this.goBack} className="headerButton link">
                            <ion-icon name="chevron-back-outline" role="img" className="md hydrated" aria-label="chevron back outline"></ion-icon>
                            Back
                        </button>
                    </div>
                    <div className="pageTitle">User List</div>
                    <div className="right">
                        <button className="headerButton link" data-bs-toggle="modal" data-bs-target="#searchActionSheet">
                            <ion-icon name="search-outline"></ion-icon>
                        </button>
                    </div>
                </div>

                <div id="appCapsule" className="extra-header-active full-height">
                    <div className="section mt-1 mb-2">
                        <div className="section-title">Showing {resultShowing} of "<span className="text-primary">{totalResult} results</span>" </div>
                        <div className="card">
                            {/* Your existing JSX */}
                {/* User list rendering */}
                {loading ? (
                    <div>Loading...</div>
                ) : (
                    <ul className="listview image-listview media transparent flush">
                        {userList.map(user => (
                            <li key={user.DT_RowId}>
                                <div className="item" onClick={this.viewUserDetail} data-bs-toggle="modal" data-bs-target="#ModalBasic" data-userinfo={JSON.stringify(user)}>
                                    <div className="imageWrapper">
                                        <ion-icon name="person-circle-outline"></ion-icon>
                                    </div>
                                    <div className="in">
                                        <div>
                                            {user.name}
                                            <div className="text-xsmall">
                                            <ion-icon name="bookmark-outline"></ion-icon> {user.user} &nbsp; &nbsp; 
                                            <ion-icon name="call-outline"></ion-icon> {user.mobile}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
                {/* Your existing JSX */}
                            
                        </div>
                    </div>
                    <div className="section mb-2">
                        <button disabled={remainingData <= 0}  className="btn btn-block btn-secondary" onClick={this.loadMore}> { remainingData > 0 ? 'Load More (Remaining ' + remainingData + ')' : 'No More Data' }</button>
                    </div>
                </div>

                <div className="modal fade action-sheet" id="searchActionSheet" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Filter</h5>
                            </div>
                            <div className="modal-body">
                                <div className="action-sheet-content">
                                    <form>
                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="label" >Search</label>
                                                <input type="text" className="form-control" placeholder="Name / Mobile / Email" onChange={this.handleInputChange} name="searchKey" value={this.state.searchKey} />
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>
                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="label" >User Type</label>
                                                <select className="form-control custom-select" onChange={this.handleInputChange} name="userType">
                                                    <option value="0">All User</option>
                                                    <option value="RETAILER">Retailers</option>
                                                    <option value="DISTRIBUTOR">Distributors</option>
                                                    <option value="MDISTRIBUTOR">M.Distributors</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group basic">
                                            <button type="button" onClick={this.handleSubmit} className="btn btn-primary btn-block btn-lg" data-bs-dismiss="modal">Search</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade modalbox" id="ModalBasic" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">User Details</h5>
                                <a href="#" data-bs-dismiss="modal">Close</a>
                            </div>
                            <div className="modal-body">
                                <div className="table-responsive">
                                    <table className="table table-sm test-small">
                                        <thead>
                                            <tr className='text-center'>
                                                <th scope="col" colSpan='2'>{ (userDetails.usertype) ? userDetails.usertype : '' }</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">UserId</th>
                                                <td className="text-end">{ (userDetails.user) ? userDetails.user : '' }</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Name</th>
                                                <td className="text-end">{ (userDetails.name) ? userDetails.name : '' }</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Mobile</th>
                                                <td className="text-end">{ (userDetails.mobile) ? userDetails.mobile : '' }</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Email</th>
                                                <td className="text-end">{(userDetails.meta_info && userDetails.meta_info.email) ? userDetails.meta_info.email : ''}</td>
                                            </tr>
                                            
                                            <tr>
                                                <th scope="row">Address</th>
                                                <td className="text-end">{ (userDetails.meta_info && userDetails.meta_info.address) ? userDetails.meta_info.address : ''} </td>
                                            </tr>
                                            
                                            <tr>
                                                <th scope="row">City</th>
                                                <td className="text-end">{ (userDetails.meta_info && userDetails.meta_info.city) ? userDetails.meta_info.city : ''} </td>
                                            </tr>
                                            
                                            <tr>
                                                <th scope="row">State</th>
                                                <td className="text-end">{ (userDetails.meta_info && userDetails.meta_info.state) ? userDetails.meta_info.state : ''} </td>
                                            </tr>
                                            
                                            <tr>
                                                <th scope="row">Pin Code</th>
                                                <td className="text-end">{ (userDetails.meta_info && userDetails.meta_info.pin) ? userDetails.meta_info.pin : ''} </td>
                                            </tr>
                                            {userDetails.kyc && (
                                                <React.Fragment>
                                                    <tr>
                                                        <th scope="row">Aadhar No.</th>
                                                        <td className="text-end">{userDetails.kyc.aadhar_no}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">PAN</th>
                                                        <td className="text-end">{userDetails.kyc.pan_no}</td>
                                                    </tr>
                                                </React.Fragment>
                                            )}
                                            <tr>
                                                <th scope="row">Create Date</th>
                                                <td className="text-end">{userDetails.create_date}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
                <Nav />
            </>
        )
    }
}
